import { MenuEntry } from '@nerveglobal/greenhouse-aurora-uikit'

export const config: MenuEntry[] = [
  {
    label: "Exchange",
    href: "https://aurora.trade.greenhousedex.com/",
  },
  {
    label: "Farms",
    href: "https://aurora.greenhousedex.com/farms",
  },
  {
    label: "Stake",
    href: "https://aurora.greenhousedex.com/stake",
  }
];
export const sideconfig: MenuEntry[] = [
];

export default config