import React from 'react'
import { Text } from '@nerveglobal/greenhouse-aurora-uikit'
import styled from 'styled-components'
import { RowFixed } from '../Row'

export const FilterWrapper = styled(RowFixed)`
  padding: 8px;
  flex: flex-end;
  width: auto;
  background-color: ${({ theme }) => theme.colors.invertedContrast};
  color: #000000;
  user-select: none;
  & > * {
    user-select: none;
  }
  :hover {
    cursor: pointer;
  }
`

export default function SortButton({
  toggleSortOrder,
  ascending
}: {
  toggleSortOrder: () => void
  ascending: boolean
}) {
  return (
    <FilterWrapper onClick={toggleSortOrder}>
      <Text fontSize="20px">{ascending ? '↑' : '↓'}</Text>
    </FilterWrapper>
  )
}